<template>
  <page-view :title="title">
    <div class="user_header_wrapper">
      <a-row :gutter="48" class="row">
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>用户ID</span>
            <a-input placeholder="用户ID" class="filter_input" v-model.trim="queryParam.uid" />
          </div>
        </a-col>

        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>用户名</span>
            <a-input placeholder="用户名" class="filter_input" v-model.trim="queryParam.username" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>手机号</span>
            <a-input placeholder="手机号" class="filter_input" v-model.trim="queryParam.mobile" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>状态</span>
            <a-select v-model="queryParam.customerVisit" class="filter_input" :allowClear="true" placeholder="请选择状态">
              <a-select-option v-for="item in customerVisitArr" :key="item.name" :value="item.value">{{ item.name }}</a-select-option>
            </a-select>
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>距付费天数</span>
            <a-input placeholder="天数" class="filter_input" v-model.trim="lastPayDay" />
          </div>
        </a-col>
        <a-col :lg="5" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <span>距登录天数</span>
            <a-input placeholder="天数" class="filter_input" v-model.trim="lastLoginDay" />
          </div>
        </a-col>
        <a-col :lg="3" :md="12" :sm="24" :style="{ padding: '0' }">
          <div class="filter_item">
            <a-button
              type="primary"
              class="query_btn"
              @click="
                () => {
                  $refs.table.refresh(false);
                }
              "
              >查询</a-button
            >
            <a-button @click="resetQuery" class="query_btn">重置</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="data_statistics">
      <div class="statistics_item">
        <span class="item_title">总客户数量</span>
        <div class="total">
          {{ customerGroup.total }}
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">已回访客户数量</span>
        <div class="total">
          {{ customerGroup.complete }}
        </div>
      </div>

      <div class="statistics_item">
        <span class="item_title">未回访客户数量</span>
        <div class="total">
          {{ customerGroup.unfinish }}
        </div>
      </div>
      <div class="statistics_item">
        <span class="item_title">继续跟进客户数量</span>
        <div class="total">
          {{ customerGroup.continue }}
        </div>
      </div>
    </div>

    <div class="user_table_wrapper">
      <s-table ref="table" rowKey="id" :columns="columns" :data="dataSource">
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}</span>
        <template slot="logo" slot-scope="text">
          <img :src="text" style="width: 45px;height: 45px;" />
        </template>
        <template slot="uid" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span class="uid_fat" @mouseover="handleMouse(record, 'showid')" @mouseout="handleMouse(record, 'showid')" @click="copyInfo(record.uid)">
              {{ text.trim().substring(0, 4) + '……' + text.trim().substring(text.split('').length - 4) }}
              <span class="uid_child" v-show="record.showid">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>
        <template slot="customerComment" slot-scope="text, record">
          <a-tooltip placement="right" overlayClassName="tooltip_custom" v-if="text">
            <template slot="title">
              <span>点击复制</span>
            </template>
            <span
              class="uid_fat"
              @click="copyInfo(record.customerComment)"
              @mouseover="handleMouse(record, 'showcomment')"
              @mouseout="handleMouse(record, 'showcomment')"
              >{{ text.trim().substring(0, 2) + '……' + text.trim().substring(text.split('').length - 2) }}
              <span class="uid_child uid_width" v-show="record.showcomment">{{ text }}</span>
            </span>
          </a-tooltip>
        </template>

        <template slot="operation" slot-scope="text, record">
          <span class="edit_btn" @click="handleOpenVisitModal(record)">修改</span>
        </template>
      </s-table>
    </div>
    <a-modal
      title="修改"
      :width="560"
      :visible="visitModal"
      @cancel="
        () => {
          visitModal = false;
        }
      "
      @ok="handleOk"
    >
      <div class="input_group">
        <span>状态：</span>
        <a-select v-model="visitData.customerVisit" style="width:240px" :allowClear="true" placeholder="请选择状态">
          <a-select-option v-for="item in customerVisitArr" :key="item.name" :value="item.value">{{ item.name }}</a-select-option>
        </a-select>
      </div>
      <div class="input_group">
        <span>备注：</span>
        <a-textarea placeholder="备注" :autoSize="{ minRows: 5 }" v-model="visitData.customerComment" style="resize:none;flex:1" />
      </div>
    </a-modal>
  </page-view>
</template>
<script>
import { STable } from '@/components';
import { PageView } from '@/layouts';
import { accountListByCustomer, accountGroupByCustomer, accountGet, accountCustomerUpdate } from '@api';
import { formatDate, roleCodeToName, customerVisitObj, jsonToArray } from '@/utils/util';

const rolesCustomRender = (value, row, index) => {
  let roleNames = '';
  value.map((it) => {
    roleNames += roleCodeToName[it].name + ';';
  });
  return roleNames;
};

export default {
  data() {
    return {
      rolesList: [],
      edit: { show: false },
      queryParam: {
        sip: true
      },
      lastPayDay: '',
      lastLoginDay: '',
      customerVisitArr: jsonToArray(customerVisitObj),
      visitData: {},
      visitModal: false,
      customerGroup: {
        total: 0,
        unfinish: 0,
        complete: 0,
        continue: 0
      },
      columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '用户ID',
          dataIndex: 'uid',
          scopedSlots: { customRender: 'uid' }
        },
        {
          title: '用户名',
          dataIndex: 'username'
        },
        {
          title: '角色',
          dataIndex: 'roleNames',
          width: '10%'
        },
        {
          title: '手机号',
          dataIndex: 'mobile'
        },
        {
          title: '状态',
          dataIndex: 'customerVisit',
          customRender: (text) => customerVisitObj[text]
        },
        {
          title: '备注',
          dataIndex: 'customerComment',
          scopedSlots: { customRender: 'customerComment' }
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          customRender: formatDate,
          width: 150
        },
        {
          title: '修改时间',
          dataIndex: 'updateTime',
          customRender: formatDate,
          width: 150
        },
        {
          title: '最后登录时间',
          dataIndex: 'lastLoginTime',
          customRender: formatDate,
          width: 150
        },
        {
          title: '最后付款时间',
          dataIndex: 'lastPayTime',
          customRender: formatDate,
          width: 150
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' }
        }
      ],
      dataSource: (parameter) => {
        if (this.lastPayDay > 0) {
          this.queryParam.lastPayTimeStart = 1;
          this.queryParam.lastPayTimeEnd = new Date().getTime() - Number(this.lastPayDay) * 86400000;
        } else if (this.lastPayDay.trim().length < 1) {
          delete this.queryParam.lastPayTimeEnd;
          delete this.queryParam.lastPayTimeStart;
        } else if (this.lastPayDay == 0) {
          this.queryParam.lastPayTimeEnd = 0;
          this.queryParam.lastPayTimeStart = 0;
        }
        if (this.lastLoginDay > 0) {
          this.queryParam.lastLoginTimeStart = 1;
          this.queryParam.lastLoginTimeEnd = new Date().getTime() - Number(this.lastPayDay) * 86400000;
        } else if (this.lastLoginDay.trim().length < 1) {
          delete this.queryParam.lastLoginTimeEnd;
          delete this.queryParam.lastLoginTimeStart;
        } else if (this.lastLoginDay == 0) {
          this.queryParam.lastLoginTimeEnd = 0;
          this.queryParam.lastLoginTimeStart = 0;
        }
        return accountListByCustomer(Object.assign(parameter, this.queryParam)).then((result) => {
          result.data.list.forEach((item) => {
            item.showid = false;
            item.showcomment = false;
          });

          return result.data;
        });
      }
    };
  },
  created() {
    //修改角色列表
    const array = [];
    const roleObj = {
      FORWARDER: { name: '代发', id: 3 },
      OEM: { name: '贴牌', id: 5 }
    };
    for (let key in roleObj) {
      array.push({ name: roleObj[key].name, value: key });
    }
    this.rolesList = array;
    this.customerStatistics();
  },
  components: {
    PageView,
    STable
  },
  methods: {
    async customerStatistics() {
      this.customerGroup.total = 0;
      const res = await accountGet();
      const res2 = await accountGroupByCustomer(res.data.uid);
      res2.data.forEach((item) => {
        this.customerGroup.total += item.num;
      });
      const arr1 = res2.data.filter((item) => {
        return item.customerVisit == 'YES';
      });
      const arr2 = res2.data.filter((item) => {
        return item.customerVisit == 'NO';
      });
      const arr3 = res2.data.filter((item) => {
        return item.customerVisit == 'CONTINUE';
      });
      this.customerGroup.complete = arr1.length > 0 ? arr1[0].num : 0;
      this.customerGroup.unfinish = arr2.length > 0 ? arr2[0].num : 0;
      this.customerGroup.continue = arr3.length > 0 ? arr3[0].num : 0;
    },
    handleMouse(record, key) {
      record[key] = !record[key];
    },

    // 重置
    resetQuery() {
      this.lastPayDay = '';
      this.lastLoginDay = '';
      this.queryParam = {};
      this.$refs.table.refresh(false);
    },

    handleOpenVisitModal(data) {
      this.visitData = JSON.parse(JSON.stringify(data));
      this.visitModal = true;
    },
    handleOk() {
      const { visitData } = this;
      const data = {
        uid: visitData.uid,
        customerVisit: visitData.customerVisit,
        customerComment: visitData.customerComment
      };
      accountCustomerUpdate(data).then((res) => {
        if (res.success) {
          this.$message.success('修改成功');
          this.$refs.table.refresh(false);
          this.customerStatistics();
          this.visitModal = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  computed: {
    title() {
      return this.$route.meta.title;
    }
  }
};
</script>

<style lang="less" scoped>
.user_header_wrapper {
  padding: 24px 56px 0;
  margin: -24px -24px 24px;
  background-color: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  .row {
    margin-left: 0 !important;
  }

  .filter_item {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    span {
      width: 70px;
      text-align: right;
    }
    .filter_input {
      width: 240px;
      height: 32px;
      margin-left: 6px;
    }
    .query_btn {
      margin-left: 12px;
    }
  }
}
.data_statistics {
  background: #fff;
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  margin-bottom: 24px;
  padding: 32px 0;
  display: flex;
  .statistics_item {
    flex: 1;
    border-right: 1px solid rgba(240, 240, 240, 1);
    text-align: center;
    .item_title {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      margin-bottom: 8px;
    }
    .total {
      font-size: 30px;
      font-family: Roboto;
      color: rgba(0, 0, 0, 0.85);
    }

    &:last-child {
      border-right: 0;
    }
  }
}
.user_table_wrapper {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 20px rgba(15, 41, 131, 0.1);
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: PingFang SC;
  padding: 24px;
}

.input_group {
  display: flex;
  margin-bottom: 24px;
  span {
    margin-top: 5px;
    width: 60px;
    text-align: right;
  }
}
.uid_fat {
  position: relative;
  cursor: pointer;
  .uid_child {
    position: absolute;
    bottom: -40px;
    left: 32px;
    padding: 4px 8px;
    background-color: #f0f0f0;
    border-radius: 2px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
  }
  .uid_width {
    width: 200px;
  }
}
.top5 {
  width: 320px;
  height: 32px;
}
.pagin {
  margin-top: 24px;
  margin-right: 24px;
  text-align: right;
}
.edit_btn {
  cursor: pointer;
  color: #1890ff;
}
</style>
